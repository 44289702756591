import { LogLevel } from '@wellro/utils';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAgWd7sdHMnm4sSBItDLKw1HvlD8aWkOe4',
    authDomain: 'wellro-wellness-prod.firebaseapp.com',
    projectId: 'wellro-wellness-prod',
    storageBucket: 'wellro-wellness-prod.appspot.com',
    messagingSenderId: '691919511102',
    appId: '1:691919511102:web:3d673ef15ab07d840c3b6f',
    measurementId: 'G-0WS7QYS2N1',
  },
  tenantId: 'system-user-muorg',
  logLevel: LogLevel.WARNS,
  hostUrl: 'https://admin.wellro.life',
};
